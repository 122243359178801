<template>
  <div class="teams-header">
    <b-row class="teams-header__body align-items-center">
      <b-col sm="6" class="d-flex align-items-center">
        <img
          src="@/assets/images/teams/icons/back.svg"
          alt="back"
          class="header__back-icon"
          v-if="showBackButton"
          @click="backNavigation"
        />
        <div
          v-if="isThemedIcon"
          class="topic-icon text-center"
          :style="`background:${themeColor}`"
        >
          <img :src="iconPath" alt="teams-icon" width="25" height="25" />
        </div>
        <img v-else :src="iconPath" alt="teams" width="50" height="50" />
        <div class="teams-heading d-block">
          <h4 :class="{ 'ellipsis-text': title.length > 80 }" :title="title">
            {{ title }}
          </h4>
          <p v-if="subtitle">{{ subtitle }}</p>
        </div>
      </b-col>
      <b-col sm="6">
        <div class="d-flex justify-content-end">
          <slot name="header-buttons"></slot>
        </div>
      </b-col>
    </b-row>
    <div class="teams-header__alert">
      <alert
        :variant="variant"
        :iconName="alertIconName"
        dismissible
        v-if="showAlert"
        @dismiss="onDismiss"
      >
        <slot name="alert-content"></slot>
      </alert>
    </div>
  </div>
</template>
<script>
import Alert from "@/components/WrapperComponents/Alert.vue";
export default {
  props: {
    variant: {
      type: String,
      default: "primary"
    },
    title: {
      type: String,
      default: "",
      required: true
    },
    subtitle: String,
    showAlert: {
      type: Boolean,
      default: false
    },
    iconPath: {
      type: String,
      default: ""
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    enableBrowserBack: {
      type: Boolean,
      default: true
    },
    isThemedIcon: {
      type: Boolean,
      default: false
    },
    themeColor: {
      type: String,
      default: "#e1ddff"
    },
    alertIconName: {
      type: String,
      default: ""
    }
  },
  components: { Alert },
  methods: {
    onDismiss() {
      this.$emit("dismiss");
    },
    backNavigation() {
      if (!this.enableBrowserBack) {
        this.$emit("goBack");
      } else {
        this.$router.back();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.teams-header {
  .teams-header__body {
    margin-bottom: 44px;
  }
  .teams-heading {
    margin-top: 10px;
    margin-left: 16px;
    .ellipsis-text {
      @include text-ellipsis;
      width: 300px;
      cursor: pointer;
    }
  }
  .topic-icon {
    min-width: 40px;
    height: 40px;
    background: #d3cbff;
    border-radius: 8px;
    img {
      margin-top: 8px;
    }
  }
  .header__back-icon {
    margin-right: 20px;
    cursor: pointer;
  }

  .teams-header__alert {
    margin-bottom: 30px;
  }
}
// rtl styles
[dir="rtl"] {
  .teams-header {
    .teams-heading {
      margin-right: 10px;
    }
    .header__back-icon {
      margin: 0 0 0 20px;
    }
  }
}
</style>
