<template>
  <div class="lx-teams-list">
    <Header
      :title="topicName"
      :iconPath="require('@/assets/images/icons/teams-topic-icon.svg')"
      :isThemedIcon="true"
      :themeColor="themeColor"
      :showBackButton="true"
      :showAlert="showAlert"
      variant="info"
      :enableBrowserBack="true"
      alertIconName="person-check"
    >
      <template v-slot:header-buttons>
        <div id="disabled-wrapper" v-if="getTopicDetail.has_joined_teamset">
          <Button :pill="true" disabled className="text-uppercase">{{
            $t("teams.buttons.new_team")
          }}</Button>
        </div>
        <div v-else>
          <Button
            :pill="true"
            className="text-uppercase"
            @click="$bvModal.show('create-team-modal')"
            >{{ $t("teams.buttons.new_team") }}</Button
          >
        </div>
        <b-tooltip
          v-if="getTopicDetail.has_joined_teamset"
          target="disabled-wrapper"
          >{{ tooltipData }}</b-tooltip
        >
      </template>
      <template v-slot:alert-content>
        <div class="d-flex" v-if="showAlert">
          <!-- <span style="font-weight: 500" class="teams-alert__content"
            >{{ $t("teams.posts.success") }}
          </span> -->
          {{ $t("teams.posts.success") }}
        </div>
      </template>
    </Header>
    <PaginationV2
      ref="teamListWrapper"
      dataComponentPath="components/Teams/PaginationWrappers/TeamsListWrapper.vue"
      :dataFetchURL="getTeamsListURL(courseId, topicId)"
      :authToken="authToken"
      :additionalProps="additionalProps"
    ></PaginationV2>
    <create-team-modal :topicId="this.topicId" @success="onTeamCreated()" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TeamsPaginationMixin from "@/components/Teams/PaginationWrappers/mixin.js";

import Header from "./Shared/Header.vue";
import CreateTeamModal from "./Shared/CreateTeam.vue";

export default {
  mixins: [TeamsPaginationMixin],
  components: { Header, CreateTeamModal },
  data() {
    return {
      showAlert: false
    };
  },
  computed: {
    ...mapGetters(["getTopicDetail", "allLanguage"]),
    courseId() {
      return this.$route.params.id;
    },
    topicId() {
      return this.$route.params.topicId;
    },
    topicName() {
      return this.$route.query.topicName;
    },
    themeColor() {
      return this.$route.query.themeColor;
    },
    additionalProps() {
      return {
        topicName: this.topicName,
        themeColor: this.themeColor,
        topicId: this.topicId
      };
    },
    tooltipData() {
      return this.$t("teams.teams.create.error.content");
    }
  },
  mounted() {
    this.getTopicDetails();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getTopicDetails() {
      let obj = {
        courseId: this.courseId,
        topicId: this.topicId
      };
      this.$store.dispatch("getTopicDetails", obj);
    },
    onTeamCreated() {
      this.$refs.teamListWrapper.reload();
      this.getTopicDetails();
      this.showAlert = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.lx-teams-list {
  padding: 60px;
  text-align: left;
}
</style>
